@tailwind base;
@tailwind components;
@tailwind utilities;

  * 
    /* Hide scrollbar for Chrome, Safari and Opera */
      .no-scrollbar::-webkit-scrollbar {
        display: none;
      }
    
      /* Hide scrollbar for IE, Edge and Firefox */
      .no-scrollbar {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
      }
    

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
    --primary: 240 5.9% 10%;
    --primary-foreground: 0 0% 98%;
    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 240 10% 3.9%;
    --radius: 0.5rem;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }

  .dark {
    --gradient: linear-gradient(to top left, #4568DC, #B06AB3);
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;
    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

  
  @layer utilities {
/* Home */
  .home {
    @apply no-scrollbar flex w-full flex-row max-xl:max-h-screen max-xl:overflow-y-scroll;
  }
    .home-content {
      @apply no-scrollbar flex w-full flex-1 flex-col gap-8 px-5 sm:px-8 py-7 lg:py-12 xl:max-h-screen xl:overflow-y-scroll;
    }
  
    .home-header {
      @apply flex flex-col justify-between gap-8;
    }

/* Auth */
  .auth-asset {
      @apply flex h-screen w-full sticky top-0 items-center justify-end  max-lg:hidden;
        }
/* Auth Form */
.auth-form {
  @apply flex min-h-screen w-full max-w-[420px] flex-col justify-center gap-5 py-10 md:gap-8;
}
  }
.navbar-shadow {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 1);
  /* Adjust the values as needed */
}
    /* Invert image color in light mode */
    .footer-image {
      filter: invert(1);
    }
  
    /* Ensure the image is not inverted in dark mode */
    @media (prefers-color-scheme: dark) {
      .footer-image {
        filter: invert(0);
      }
    }

        /* src/app/globals.css */
        #tsparticles {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: 0;
        }
    
        .relative {
          position: relative;
        }
    
        .relative .z-10 {
          z-index: 10;
        }

.bg-vibrant-violet-gradient {
  background: linear-gradient(135deg, #7e22ce 0%, #5b21b6 100%);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}

.footer-reflection {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: linear-gradient(to bottom, rgba(126, 34, 206, 0.2), rgba(91, 33, 182, 0));
  transform: scaleY(-1);
  opacity: 0.5;
  pointer-events: none;
  z-index: 0;
}
@keyframes writing {
  0% {
    width: 0;
  }

  50% {
    width: 70%;
  }

  100% {
    width: 100%;
  }
}

.writing-animation {
  height: 2px;
  background-color: #4A5568;
  animation: writing 2s linear infinite;
}

@layer base {
  * {
    @apply border-border;
        }
  body {
    @apply bg-background text-foreground;
        }
}


